<template>
  <div class="layout">
    <audio src="https://img.cagibrand.com/yszsg/multi/music_04.mp3" id="myaudio" loop preload hidden="true" ref="audio">
      您的浏览器不支持 audio 标签。
    </audio>
    <div class="top-nav-bar" :class="{'home-nav-bar':this.$route.name === 'home'}">
      <div class="wrap" :class="{'en-wrap':!$store.state.language}">
        <div class="logo-box">
          <img src="../static/img/logo1.png" class="logo" v-show="this.$route.name === 'home'" @click="logoClick()">
          <img src="../static/img/logo2.png" class="logo" v-show="this.$route.name !== 'home'" @click="logoClick()">
        </div>
        <div class="wrap-center">
          <div class="nav-bar-list">
            <div class="nav-bar-item" v-for="(item,index) in navBarList" :key="index" @mouseover="navEnter(index)" @mouseleave="navLeave()">
              <router-link :to="item.path" class="item-name" :active-class="index === 0 ? '' : 'router-active'">{{item.columnName}}</router-link>
              <div class="cur" v-if="navHoverIndex === 3 && navHoverIndex=== index"></div>
              <div class="nav-child" v-if="navHoverIndex === 3 && navHoverIndex=== index">
                <div v-for="(item2,index2) in navChildList" :key="index2" class="child-item" @click="navChildClick(item2.path,item2)">{{$store.state.language ? item2.title : item2.titleE}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="tools">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{$t('language')}}<i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <template v-for="(item,index) in languageList">
                <el-dropdown-item :command="item" :key="index" v-if="item.language !== defaultLanguage" :disabled="itemPage">{{item.language.toUpperCase()}}</el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>
          <template v-if="this.$route.name === 'home'">
            <img src="../static/img/layout/m_icon1.png" class="m-icon play-icon" @click="musicPause()" v-if="isPlay">
            <img src="../static/img/layout/m_icon2.png" class="m-icon" @click="musicPlay()" v-else>
          </template>
          <template v-else>
            <img src="../static/img/layout/m_icon3.png" class="m-icon play-icon" @click="musicPause()" v-if="isPlay">
            <img src="../static/img/layout/m_icon4.png" class="m-icon" @click="musicPlay()" v-else>
          </template>
        </div>
      </div>
    </div>
    <!-- 面包屑 首页以外展示-->
    <div class="breadcrumb-wrap" v-show="!this.$route.meta.breadcrumb">
      <el-breadcrumb separator-class="el-icon-d-arrow-right">
        <template v-for="(item,index) in breadcrumbList">
          <el-breadcrumb-item :key="index" :to="{ path: item.path }" v-if="breadcrumbList.length !== index + 1">{{$store.state.language ?item.meta.title :item.meta.titleE}}</el-breadcrumb-item>
          <el-breadcrumb-item :key="index" v-else>{{$store.state.language ?item.meta.title :item.meta.titleE}}</el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </div>
    <div class="content">
      <keep-alive include='home,brandList,iconList,articleList,dynamicList,aboutUs'>
        <router-view></router-view>
      </keep-alive>
    </div>
    <div class="footer">
      <sub-footer v-if="this.$route.meta.footerShow"></sub-footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import subFooter from '@/components/subFooter/subFooter'

export default {
  name: 'layout',
  inject: ['reload'],
  components: {
    subFooter
  },
  data() {
    return {
      defaultLanguage: '',
      languageList: [
        {
          language: 'ch',
          siteId: '1422454440404713472'
        },
        {
          language: 'en',
          siteId: '1422454502115508224'
        }
      ],
      navHoverIndex: '',
      navChildList: [
        {
          title: '学术专著',
          titleE: 'Academic Monograph',
          path: '/academicField/articleList',
          columnCode: '010402'
        },
        {
          title: '学术论文',
          titleE: 'Academic Papers',
          path: '/academicField/articleList',
          columnCode: '010403'
        },
        {
          title: '学术报告',
          titleE: 'Academic Report',
          path: '/academicField/articleList',
          columnCode: '010401'
        }
      ],
      isPlay: false,
      itemPage: false
    }
  },
  watch: {
    navBarList(val) {
      this.defaultLanguage = this.$store.state.language ? 'ch' : 'en'
    },
    musicState(val) {
      if (val) {
        this.musicPlay()
      } else {
        this.musicPause()
      }
    },
    $route(val) {
      // this.$store.commit('defaultLink')
      this.isItemPage(val.name)
    }
  },
  computed: {
    ...mapState({
      navBarList: (state) => {
        return state.navBarList
      },
      breadcrumbList: (state) => {
        return state.breadcrumbList
      },
      musicState: (state) => {
        return state.musicState
      }
    })
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'openAnimation') {
        vm.isPlay = true
        vm.musicPlay()
      }
    })
  },
  created() {
    this.getNavBarList()
    this.defaultLanguage = this.$store.state.language ? 'CH' : 'EN'
    this.isItemPage(this.$route.name)
  },
  methods: {
    // 是否是详情页面
    isItemPage(pageName) {
      if (
        pageName === 'brandItem' ||
        pageName === 'iconItem' ||
        pageName === 'articleDetails' ||
        pageName === 'dynamicItem'
      ) {
        this.itemPage = true
      } else {
        this.itemPage = false
      }
    },
    logoClick() {
      this.$router.replace({
        path: '/openAnimation'
      })
    },
    // 背景音乐播放
    musicPlay() {
      this.$refs.audio.play()
      this.isPlay = true
    },
    // 背景音乐暂停
    musicPause() {
      this.$refs.audio.pause()
      this.isPlay = false
    },
    ...mapActions(['getNavBarList']),
    // 切换语言
    handleCommand(item) {
      this.defaultLanguage = item.language
      let lang = item.language
      if (lang === 'ch') {
        this.$store.commit('changeLanguage', true)
      } else {
        this.$store.commit('changeLanguage', false)
      }
      localStorage.setItem('locale', lang)
      this.$i18n.locale = lang
      localStorage.setItem('siteId', item.siteId)
      this.reload()
    },
    // nav鼠标移入
    navEnter(index) {
      this.navHoverIndex = index
    },
    // nav鼠标移出
    navLeave() {
      this.navHoverIndex = ''
    },
    navChildClick(path, item2) {
      this.$router.push({
        path,
        query: {
          columnCode: item2.columnCode
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 1300px;
  .top-nav-bar {
    height: 60px;
    width: 100%;
    background: #fff;
    box-shadow: rgba($color: #231815, $alpha: 0.18) 0 1px 5px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 200;
    .wrap {
      width: 1140px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      position: relative;
    }
    .en-wrap {
      width: 1240px;
    }
    .logo-box {
      width: 300px;
      display: flex;
      align-items: center;
      overflow: hidden;
      .logo {
        cursor: pointer;
      }
    }
    .wrap-center {
      flex: 1;
    }
    .nav-bar-list {
      display: flex;
      flex: 1;
      height: 100%;
      font-size: 18px;
      line-height: 18px;
      justify-content: space-between;
      align-items: center;
      color: #595757;
      max-width: 1200px;
      margin: 0 auto;
      .nav-bar-item {
        cursor: pointer;
        // width: 72px;
        white-space: nowrap;
        text-align: center;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        .item-name {
          flex: 1;
          display: flex;
          align-items: center;
        }
        @keyframes myfirst {
          from {
            height: 0px;
            bottom: 0px;
          }
          to {
            height: 50px;
            bottom: -50px;
          }
        }
        .nav-child {
          position: absolute;
          bottom: -50px;
          left: 0;
          width: 100%;
          height: 50px;
          background: rgba($color: #b48c62, $alpha: 0.65);
          animation: myfirst 0.15s ease-out forwards;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 0 0 240px;
          .child-item {
            margin: 0 27px;
            font-size: 15px;
            line-height: 12px;
            color: #fff;
            &:hover {
              color: #b18962;
            }
          }
        }
        .router-active {
          color: #bd9562;
        }
      }
      .cur {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0;
          height: 0;
          margin: 0 0 0 -3px;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #bd9562;
        }
      }
    }
    .tools {
      padding: 0 0 0 120px;
      height: 100%;
      display: flex;
      align-items: center;
      @keyframes spin {
        // from {
        //   transform: rotate(0deg);
        // }
        // to {
        //   transform: rotate(360deg);
        // }
        0% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(45deg);
        }
        50% {
          transform: rotate(0deg);
        }
        75% {
          transform: rotate(-45deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }
      .m-icon {
        width: 25px;
        height: 25px;
        margin: 0 0 0 15px;
        cursor: pointer;
      }
      .play-icon {
        animation: spin 6s linear infinite;
      }
      .el-dropdown {
        width: 35px;
        text-align: right;
      }
      .el-dropdown-link {
        cursor: pointer;
        // color: #409eff;
      }
    }
  }
  .home-nav-bar {
    background: rgba($color: #af1b20, $alpha: 0.8);
    position: fixed;
    top: 0;
    left: 0;
    .wrap {
      width: 100%;
    }
    .logo-box {
      margin: 0 0 0 40px;
    }
    .nav-bar-list {
      color: #fff;
    }
    .tools {
      min-width: 300px;
      padding: 0 66px 0 128px;
      .el-dropdown {
        color: #fff;
      }
    }
  }
  .breadcrumb-wrap {
    width: 1140px;
    margin: 0 auto;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 1300px;
  }
  .footer {
    width: 1140px;
    margin: 0 auto;
  }
}
</style>