<template>
  <div class="sub-footer">
    <div class="title">{{$t('fLink')}}</div>
    <ul class="link-list">
      <li class="link-item" v-for="(item,index) in LinksList" :key="index" @click="linkClick(item.websiteUrl)">{{$store.state.language ? item.websiteName : item.englishName}}</li>
    </ul>
    <div v-if="this.$route.name === 'home'" class="icp" @click="icp()">浙ICP备19012954号-2</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      LinksList: (state) => {
        return state.LinksList
      }
    })
  },
  created() {
    // this.$store.commit('defaultLink')
  },
  methods: {
    linkClick(link) {
      if (link.indexOf('http') === -1) {
        link = 'http://' + link
      }
      window.open(link)
    },
    icp() {
      window.open('https://beian.miit.gov.cn/')
    }
  }
}
</script>

<style lang="scss">
.sub-footer {
  min-height: 135px;
  background: url('../../static/img/layout/footer.png') no-repeat center;
  background-size: 100% 100%;
  padding: 0 0 20px 0;
  .title {
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    padding: 39px 0 0 0;
    text-align: center;
  }
  .link-list {
    color: #fff;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 15px;
    line-height: 15px;
    margin: 21px 0 0 0;
    .link-item {
      background: url('../../static/img/layout/link_icon.png') no-repeat left
        center;
      background-size: 25px 8px;
      padding: 0 0 0 33px;
      margin: 0 30px 20px;
      cursor: pointer;
    }
  }
  .icp {
    cursor: pointer;
    text-align: center;
    color: #fff;
  }
}
</style>